// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
// import kebabCase from 'lodash/kebabCase'

import classNames from 'classnames'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { StaticQuery, graphql } from 'gatsby'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

import StandardPageWrapper from '../standard-page-wrapper'
import '../standard-page-wrapper/style.less'

import './style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query TutorialPageWrapperQuery {
    allTutorialsJson {
      edges {
        node {
          title
          routeSlug
          publishedTimestamp
          order
          contentBlocks {
            type
            content
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page Wrapper */
const PageWrapper = ({ children, className, ...rest }) => (
  <StaticQuery
    query={query}
    render={(data) => {
      const {
        allTutorialsJson: { edges },
      } = data

      const nodes = map(edges, 'node')
      const ordered = orderBy(nodes, ['order', 'asc'])

      const {
        location: { hash },
        pageContext: {
          intl: { originalPath },
        },
      } = rest

      return (
        <StandardPageWrapper
          className={classNames(className, 'tutorial-page')}
          {...rest}
        >
          <div style={{ borderTop: '4px solid var(--gold2)', width: '100%' }}>
            <Row
              gutter={[
                { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
                { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
              ]}
              className="adjust-col-spacing"
            >
              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <aside
                  style={{
                    marginRight: '1.1rem',
                  }}
                >
                  <ul className="table-of-contents">
                    {map(
                      ordered,
                      ({ title, order, routeSlug, contentBlocks }) => (
                        <li>
                          <Link
                            className={classNames('page', {
                              active: originalPath === routeSlug,
                            })}
                            to={routeSlug}
                          >
                            <span>
                              {order}. {title}
                            </span>
                          </Link>
                        </li>
                      )
                    )}
                  </ul>
                </aside>
              </Col>
              <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
                {children}
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <aside
                  style={{
                    borderTop: '4px solid var(--orange1)',
                    marginTop: '1.1rem',
                    padding: '1.1rem',
                    background: 'var(--gray2)',
                    borderBottomLeftRadius: '0.55rem',
                    borderBottomRightRadius: '0.55rem',
                    overflow: 'hidden',
                  }}
                >
                  <Row
                    gutter={[
                      { xs: 8, sm: 12, md: 16, lg: 20, xl: 24, xxl: 28 },
                      { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
                    ]}
                  >
                    <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                      <h3>Meet us in-person</h3>
                      <p>
                        If you are in Auroville you can request an in-person
                        tutorial of the Aura App. Please write to us at
                        <Link to="mailto:admin@auranetwork.app">
                          admin@auranetwork.app
                        </Link>{' '}
                        and let us know when and where you'd like to meet us.
                      </p>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                      <h3>Request support</h3>
                      <p>
                        If you are new to the App and need support you can fill
                        the form on <Link to="/support">this page</Link> or you
                        can write to us at
                        <Link to="mailto:admin@auranetwork.app">
                          admin@auranetwork.app
                        </Link>
                        .
                      </p>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                      <h3>Aura Community</h3>
                      <p>
                        You can learn more about the Aura Network by listening
                        to our podcasts,{' '}
                        <Link to="/aura-talks/s2/e12/exploring-prosperity-part-two">
                          Aura Talks
                        </Link>
                        , or by exploring{' '}
                        <Link to="/aura-stories">
                          how others are participating on the network
                        </Link>
                        , or by looking at{' '}
                        <Link to="/community-events">
                          past community events
                        </Link>
                        .
                      </p>
                    </Col>
                  </Row>
                </aside>
              </Col>
            </Row>
          </div>
        </StandardPageWrapper>
      )
    }}
  />
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default PageWrapper
