// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

import map from 'lodash/map'
import kebabCase from 'lodash/kebabCase'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Breadcrumbs from '../breadcrumbs'
import '../breadcrumbs/style.less'

import Video from '../video'
import '../video/style.less'

import TutorialPageWrapper from '../tutorial-page-wrapper'
import '../tutorial-page-wrapper/style.less'

import AuraNetworkAppOverviewFeature from '../feature-aura-network-app-overview'
import '../feature-aura-network-app-overview/style.less'

import './style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const pageQuery = graphql`
  query TutorialPageTemplateQuery($routeSlug: String) {
    allTutorialsJson(filter: { routeSlug: { eq: $routeSlug } }) {
      edges {
        node {
          title
          routeSlug
          publishedTimestamp
          contentBlocks {
            type
            content
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
class Page extends React.PureComponent {
  /** [constructor description] */
  constructor() {
    super()
  }

  /** [render description] */
  render() {
    const {
      data: {
        allTutorialsJson: {
          edges: [
            {
              node: { title, routeSlug, publishedTimestamp, contentBlocks },
            },
          ],
        },
      },
    } = this.props

    const pageSchema = {
      title,
      slug: routeSlug.substring(1),
      abstract: title,
      breadcrumbs: [
        { title: 'Homepage', slug: '' },
        { title: 'Tutorials', slug: '#' },
        { title, slug: `/${routeSlug.substring(1)}` },
      ],
    }

    return (
      <TutorialPageWrapper pageSchema={pageSchema} {...this.props}>
        <Breadcrumbs breadcrumbs={pageSchema.breadcrumbs} />
        <h1>{title}</h1>
        {map(contentBlocks, (block) => {
          const { type, content } = block
          let returnThis = <Fragment />

          if (type === 'text') {
            returnThis = <p>{content}</p>
          }

          if (type === 'title') {
            returnThis = <h2 id={kebabCase(content)}>{content}</h2>
          }

          if (type === 'video') {
            returnThis = <Video url={content} />
          }

          return returnThis
        })}
        {title === 'App overview' && <AuraNetworkAppOverviewFeature />}
      </TutorialPageWrapper>
    )
  }
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    headerState: state.headerState,
  })
  // dispatch => ({
  //   updateHeaderState(payload) {
  //     dispatch(updateHeaderState(payload))
  //   },
  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
